<template>
  <div id="app"
       class="app">
    <NavTop v-if="$route.meta.navTop" />
    <!-- <van-nav-bar v-if="!$route.meta.hiddenTitle" :title="$route.meta.title" left-text="返回" left-arrow @click-left="goBack"/> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && isRouterAlive && $store.state.refresh"></router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive && isRouterAlive && $store.state.refresh" />
    <goHome @goHome="openPage('home')"
            v-if="$store.state.userSource == 0"
            v-show="!$route.meta.noback" />
    <!-- <GuestGuide v-if="$route.meta.guest && $route.meta.guest.showBar && !$store.state.userToken" /> -->
    <LoginPop />
    <!-- <div class="goHome" v-show="!$route.meta.noback" @click="openPage('home')">
      回首页
      <img src="https://hxlxxpt.hongxinglin.com/static/hxlpt/discovers/muscle/%E7%BB%84%207645%402x.png" style="width: 70px;height: 70px;" />
    </div>-->
    <!-- 直播间加载中 -->
    <SkeletonLive v-if="$store.state.isLoading && ['jump', 'bjyRoom', 'bjyPlayBack'].includes(this.$route.name)" />
    <!-- 弹框广告 -->
    <AdPopup />
  </div>
</template>

<script>
  import goHome from "@/components/goHome.vue";
  // import GuestGuide from "@/components/GuestGuide.vue";
  import LoginPop from "@/views/login/components/LoginPop.vue";

  import NavTop from "@/components/NavTop.vue";
  import SkeletonLive from "@/components/SkeletonLive.vue";
  import AdPopup from "@/components/AdPopup/index.vue";
  export default {
    name: "App",
    provide() {
      //直播间重载操作
      return {
        reload: this.reload,
        formatTime: this.formatTime,
      };
    },
    components: {
      goHome,
      // GuestGuide,
      NavTop,
      LoginPop,
      SkeletonLive,
      AdPopup,
    },
    data() {
      return {
        isRouterAlive: true,
      };
    },
    watch: {
      $route() {
        if (window._czc) {
          let location = window.location;
          let contentUrl = location.pathname + location.hash;
          let refererUrl = "/";
          window._czc.push(["_trackPageview", contentUrl, refererUrl]);
        }
      },
    },
    mounted() {
      // 友盟统计添加
      const script = document.createElement("script");
      script.src =
        "https://s4.cnzz.com/z_stat.php?id=1280550994&web_id=1280550994";
      script.language = "JavaScript";
      document.body.appendChild(script);
    },
    methods: {
      // 转化时间显示格式 . 分割
      formatTime(time) {
        if (typeof time == "string") {
          time = time.replace(/[^0-9:\s]/g, ".");
        }
        return time;
      },
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(function () {
          this.isRouterAlive = true;
        });
      },
    },
  };
</script>

<style lang="less">
  @import url("./assets/reset.less");
  // .goHome {
  //   position: fixed;
  //   bottom: 70px;
  //   right: -13px;
  //   // right: 0;
  //   // background: #D44229;
  //   line-height: 25px;
  //   padding: 0 5px 0 10px;
  //   border-radius: 13px 0 0 13px;
  //   font-size: 12px;
  //   color: #fff;
  //   z-index: 99;
  // }
  .guest {
    padding-bottom: 44px !important;
  }
  @media (min-width: 750px) {
    .van-popup--bottom,
    .van-popup--top,
    .pcBtn {
      width: 750px !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto !important;
    }
  }
</style>
