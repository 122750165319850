let exam = [
  {
    path: '/exams',
    name: 'exams',
    component: () => import('@/views/exam/exams.vue'),
    meta: {
      title: '我的考试',
    }
  },
  {
    path: '/examIndex',
    name: 'examIndex',
    component: () => import('@/views/exam/index.vue'),
    meta: {
      title: '考试首页',
      guest: {
        click: true, //游客模式点击操作需要授权
      }
    }
  },
  {
    path: '/examPaper',
    name: 'examPaper',
    component: () => import('@/views/exam/paper.vue'),
    meta: {
      title: '开始考试',
      noback: true
    }
  },
  {
    path: '/examResult',
    name: 'examResult',
    component: () => import('@/views/exam/result.vue'),
    meta: {
      title: '考试结果',
    }
  },
  {
    path: '/examTops',
    name: 'examTops',
    component: () => import('@/views/exam/tops.vue'),
    meta: {
      title: '考试排行榜',
    }
  }
]
export default exam