
    let activitys = [
    {
      path: '/activeList',
      name: 'activeList',
			component: () => import('@/views/activitys/activeList.vue'),
      meta: {
        title: '活动列表',
        // keepAlive:true
      }
    },
    {
      path: '/activity/index',
      name: 'activeIndex',
      component: () => import('@/views/activitys/index.vue'),
      meta: {
        title: '课程活动主页',
      },
    },
    {
      path: '/activity/notice',
      name: 'notice',
      component: () => import('@/views/activitys/notice.vue'),
      meta: {
        title: '公告',
      },
    },
    {
      path: '/activity/clockTops',
      name: 'clockTops',
      component: () => import('@/views/activitys/clockTops.vue'),
      meta: {
        title: '打卡榜单',
				// keepAlive:true
      }
    },
    {
      path: '/activity/tops2',
      name: 'tops2',
      component: () => import('@/views/activitys/tops2.vue'),
      meta: {
        title: '医案排行榜',
				// keepAlive:true
      }
    },
    {
      path:'/activity/teachersDay',
      name:'teachersDay',
      component: () => import('@/views/activitys/teachersDay/teachersDay.vue'),
      meta:{
        title:'红杏林教师节特别活动'
      }
    },
    {
      path:'/activity/detail',
      name:'detail',
      component: () => import('@/views/activitys/teachersDay/videoDetail.vue'),
      meta:{
        title:'红杏林教师节特别活动'
      }
    },
    {
      path:'/activity/detail2',
      name:'detail2',
      component: () => import('@/views/activitys/teachersDay/videoDetail2.vue'),
      meta:{
        title:'红杏林教师节特别活动'
      }
    },
    {
      path:'/activity/easyBook',
      name:'easyBook',
      component: () => import('@/views/activitys/easyBook/index.vue'),
      meta:{
        title:'领取证书'
      }
    },
	]
 export default activitys