
import store from '@/store'
// 指令执行绑定的函数
let fn = function () { }
// 点击事件先处理auth
const checkAuth = function (e) {
  const tk = localStorage.getItem('userToken')
  if (!tk) {
    // 授权前执行操作（缓存数据）
    if (store.state.beforeAuth && typeof store.state.beforeAuth == 'function') store.state.beforeAuth()
    // 去微信授权
    store.commit('WX_AUTH')
  } else {
    fn()
  }
}
export default {
  inserted(el, binding, vnode) {
    fn = binding.value || fn
    if (fn instanceof Function) {
      el.addEventListener('click', checkAuth, false);
    } else {
      throw new Error(`使用方式： v-authed="fn"`)
    }
  },
  unbind(el) {
    el.removeEventListener('click', checkAuth, false);
  }
}
