let user = [
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/user/user.vue"),
    meta: {
      title: "我的",
      hiddenTitle: true,
      keepAlive: false,
      noback: true,
    },
  },
  {
    path: "/user/set",
    name: "set",
    component: () => import("@/views/user/set.vue"),
    meta: {
      title: "设置",
    },
  },
  {
    path: "/user/switchAccount",
    name: "switchAccount",
    component: () => import("@/views/user/switchAccount.vue"),
    meta: {
      title: "账号登录",
      noback: true,
    },
  },
  {
    path: "/user/bind",
    name: "bind",
    component: () => import("@/views/user/bind.vue"),
    meta: {
      title: "绑定手机号",
      freeAccess: true,
      noback: true,
    },
  },
  {
    path: "/user/phone",
    name: "phone",
    component: () => import("@/views/user/phone.vue"),
    meta: {
      title: "绑定手机",
    },
  },
  {
    path: "/user/countryList",
    name: "countryList",
    component: () => import("@/views/user/countryList.vue"),
    meta: {
      title: "选择国家地区",
      keepAlive: true,
    },
  },
  {
    path: "/user/messageList",
    name: "messageList",
    component: () => import("@/views/user/messageList.vue"),
    meta: {
      title: "我的消息",
    },
  },
  {
    path: "/user/message",
    name: "message",
    component: () => import("@/views/user/message.vue"),
    meta: {
      title: "通知详情",
    },
  },
  {
    path: "/user/collectList",
    name: "collectList",
    component: () => import("@/views/user/collectList.vue"),
    meta: {
      title: "我的收藏",
    },
  },
  {
    path: "/user/proveList",
    name: "proveList",
    component: () => import("@/views/user/proveList.vue"),
    meta: {
      title: "我的证书",
    },
  },
  {
    path: "/user/prove",
    name: "prove",
    component: () => import("@/views/user/prove.vue"),
    meta: {
      title: "证书详情",
    },
  },
  {
    path: "/user/feedback",
    name: "feedback",
    component: () => import("@/views/user/feedback.vue"),
    meta: {
      title: "院长信箱",
      noback: true,
    },
  },
  {
    path: "/user/feedbackInfo",
    name: "feedbackInfo",
    component: () => import("@/views/user/feedbackInfo.vue"),
    meta: {
      title: "消息详情",
      noback: true,
    },
  },
  {
    path: "/user/logisticsQuery",
    name: "logisticsQuery",
    component: () => import("@/views/user/logistics.vue"),
    meta: {
      title: "物流查询",
      freeAccess: true,
    },
  },
  {
    path: "/user/logisticsDetail",
    name: "logisticsDetail",
    component: () => import("@/views/user/logisticsDetail.vue"),
    meta: {
      title: "物流详情",
      freeAccess: true,
    },
  },
  {
    path: "/user/coupon",
    name: "coupon",
    component: () => import("@/views/user/coupon.vue"),
    meta: {
      title: "优惠券",
    },
  },
  {
    path: "/user/merchant",
    name: "merchant",
    component: () => import("@/views/user/merchant.vue"),
    meta: {
      title: "商户入驻",
    },
  },
  {
    path: "/user/merchantProtocol",
    name: "merchantProtocol",
    component: () => import("@/views/user/merchantProtocol.vue"),
    meta: {
      title: "红杏林商户入驻协议",
    },
  },
  {
    path: "/user/aboutUs",
    name: "aboutUs",
    component: () => import("@/views/user/aboutUs.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/user/certificateInfo",
    name: "certificateInfo",
    component: () => import("@/views/user/certificateInfo.vue"),
    meta: {
      title: "证照信息",
    },
  },
  {
    path: "/user/medicalCard",
    name: "medicalCard",
    component: () => import("@/views/user/medicalCard.vue"),
    meta: {
      title: "我的诊疗卡",
      keepAlive: true,
    },
  },
  {
    path: "/user/medicalCardDetail",
    name: "medicalCardDetail",
    component: () => import("@/views/user/medicalCardDetail.vue"),
    meta: {
      title: "诊疗卡详情",
    },
  },
  {
    path: "/user/account",
    name: "account",
    component: () => import("@/views/user/account.vue"),
    meta: {
      title: "账号与安全",
    },
  },
  {
    path: "/user/couponReceive",
    name: "CouponReceive",
    component: () => import("@/views/user/couponReceive.vue"),
    meta: {
      title: "领取优惠券",
      guest: {
        click: true, //游客模式点击操作需要授权
      },
    },
  },
  {
    path: "/user/couponDetail",
    name: "CouponDetail",
    component: () => import("@/views/user/couponDetail.vue"),
    meta: {
      title: "可用商品",
    },
  },
];

export default user;
