 <template>
  <div class="skeleton">
    <van-skeleton animate
                  class="sk_top"
                  :row="1"
                  :row-width="['100%']" />
    <div class="sk_tab">
      <van-skeleton animate
                    class="active_tab"
                    :row="1"
                    :row-width="['30px']" />
      <van-skeleton animate
                    :row="1"
                    :row-width="['30px']" />
    </div>

    <div class="sk_body">
      <div class="mt20"
           v-for="item in 4"
           :key="item">
        <van-row justify="center">
          <van-col span="2">
            <van-skeleton animate
                          class="avatar"
                          avatar />
          </van-col>
          <van-col span="22">
            <van-skeleton animate
                          class="sk_main"
                          title
                          title-width="76px"
                          :row-width="['67px','100%','60%']"
                          :row="3" />
          </van-col>
        </van-row>
      </div>
    </div>

    <div class="sk_footer flex">
      <van-skeleton animate
                    class="adapt"
                    round
                    row="1"
                    :row-width="['100%']" />
      <van-skeleton animate
                    class="avatar_bottom"
                    avatar
                    round />
      <van-skeleton animate
                    class="avatar_bottom"
                    avatar
                    round />
      <van-skeleton animate
                    class="avatar_bottom"
                    avatar
                    round />
    </div>
    <van-loading class="sk_loading"
                 type="spinner"
                 size="24px"
                 text-size="12px"
                 text-color="#fff"
                 vertical>加载中...</van-loading>
  </div>

</template>
<script>
  export default {
    name: "SkeletonLive",
  };
</script>
<style scope lang="less">
  .skeleton {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #fff;
    .mt10 {
      margin-top: 10px;
    }
    .mt20 {
      margin-top: 20px;
    }
    .mt30 {
      margin-top: 30px;
    }
  }
  .van-skeleton__row {
    height: 20px;
  }
  .van-skeleton__row,
  .van-skeleton__avatar,
  .van-skeleton__title {
    background-color: #ebebeb;
  }
  .sk_top {
    padding: 0;
    .van-skeleton__row {
      height: calc((100vw / 375) * 210);
    }
  }
  .sk_tab {
    padding: 5px 0;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    .van-skeleton__row {
      height: 20px;
    }
    .active_tab {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 16px;
        bottom: -3px;
        width: 30px;
        border-bottom: 2px solid #d44229;
      }
    }
  }
  .avatar {
    padding-right: 0;
    .van-skeleton__avatar {
      width: 20px;
      height: 20px;
    }
  }
  .sk_body {
    padding-top: 20px;
    background-color: #f7f7f7;
  }
  .sk_main {
    .van-skeleton__title {
      margin-block: 3px;
      display: inline-block;
      height: 14px;
    }
    .van-skeleton__title + .van-skeleton__row {
      margin-top: 0;
      margin-left: 10px;
      display: inline-block;
    }
  }
  .sk_footer {
    padding: 14px 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 57px;
    background-color: #f7f7f7;
  }
  .avatar_bottom {
    padding: 0 5.5px;
    &:last-child {
      padding-right: 16px;
    }
    .van-skeleton__avatar {
      margin: 0;
      width: 29px;
      height: 29px;
    }
  }
  .sk_loading {
    padding: 10px 17px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    border-radius: 8px;
    background-color: rgba(79, 79, 79, 1);
  }
</style>

