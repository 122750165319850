// 弹框广告
import Vue from "vue";
import { Toast } from "vant";
import {
  getExposureByPage,
  isExposure,
  eventPointOrder,
  eventPoint,
} from "@/api/adPopup";
const adPopup = {
  state: {
    visible: false, // 是否弹框广告 弹出
    adData: undefined, // 弹框广告数据
    accessData: undefined, // 进入 离开 跳转页面的数据
    tempPage: undefined, // 记录特定操作后访问就弹出的页面 该页面默认访问不弹出 做了一定操作后需要访问就弹出的页面  例：信息采集提交后
  },
  mutations: {
    // 广告显示 隐藏
    SET_VISIBLE(state, data) {
      state.visible = data;
    },

    // 广告数据
    SET_ADDATA(state, data) {
      state.adData = data;
    },

    // 用户点击进入跳转页面 保留进入的数据 离开再次上报需要使用
    SET_ACCESSDATA(state, data) {
      state.accessData = data;
    },

    // 临时记录 记录信息采集保存成功后 回退或跳转到某个路由时 需要查询 提交成功弹框广告
    SET_TEMPPAGE(state, data) {
      state.tempPage = data;
    },
  },

  getters: {
    tempPage(state) {
      return state.tempPage;
    },
  },

  actions: {
    // 执行广告弹窗业务
    async GetExposure({ state, commit, dispatch, rootState }, data) {
      const adData = await getPageExposure(data);
      const isExpos = await checkExposure({
        huid: rootState.huid,
        id: adData.adId,
        winId: adData.winId,
      });
      commit("SET_VISIBLE", isExpos);
      if (isExpos) {
        // 页面曝光 记录广告数据 清空历史跳转页面数据  上报
        commit("SET_ADDATA", adData);
        commit("SET_ACCESSDATA");
        dispatch("EventPoint", { eventType: 1 });
      }
    },

    // 曝光点击事件  上报
    async EventPoint({ state, commit, rootState }, data) {
      const nowTime = new Date().getTime() - rootState.timeDiff;
      let leaveData = {};
      if (data.id) {
        leaveData = {
          leaveTime: Vue.prototype
            .$moment(nowTime)
            .format("YYYY-MM-DD HH:mm:ss"),
          eventValue: nowTime - new Date(data.eventTime).getTime(),
        };
      }
      const formData = {
        clientType: 3, // 客户端 h5 3
        userId: rootState.huid,
        adId: state.adData.adId,
        winId: state.adData.winId,
        pageId: state.adData.pageId,
        eventTime: Vue.prototype.$moment(nowTime).format("YYYY-MM-DD HH:mm:ss"),
        ...data,
        ...leaveData,
      };
      console.log("上报的数据", formData);
      const res = await eventPoint(formData);
      if (res.data?.code == 200) {
        return {
          ...formData,
          id: res.data.data,
        };
      }
      Toast.fail(res.data?.message || res.status);
    },

    // 成交事件上报
    async EventPointOrder({ state, commit, rootState }, data) {
      const nowTime = new Date().getTime() - rootState.timeDiff;
      const formData = {
        clientType: 3, // 客户端 h5 3
        eventType: 3, // 成交事件 3
        userId: rootState.huid,
        adId: state.adData.adId,
        winId: state.adData.winId,
        pageId: state.adData.pageId,
        eventTime: Vue.prototype.$moment(nowTime).format("YYYY-MM-DD HH:mm:ss"),
        ...data,
      };
      console.log("上报的数据", formData);
      const res = await eventPointOrder(formData);
      if (res.data?.code == 200) {
        return {
          ...formData,
          id: res.data.data,
        };
      }
      Toast.fail(res.data?.message || res.status);
    },
  },
};

// 获取页面是否有广告
function getPageExposure(params) {
  return new Promise(async (resolve) => {
    const res = await getExposureByPage(params);
    if (res.data?.code == 200) {
      const adData = res.data.data || {};
      if (adData.adId) {
        // 解析跳转页面路由信息
        const [path, jumpData] = adData.jumpUrl.split("?");
        const jumpArrData = jumpData.split("&");
        let query = {};
        for (let i = 0, len = jumpArrData.length; i < len; i++) {
          const item = jumpArrData[i].split("=");
          query[item[0]] = item[1];
        }
        resolve({ ...adData, route: { path, query } });
      }
      return;
    }
    if (res.data?.message?.indexOf("广告曝光信息不存在") >= 0) {
      return;
    }
    Toast.fail(res.data?.message || res.status);
  });
}

// 校验广告是否要弹出
function checkExposure(params) {
  return new Promise(async (resolve) => {
    const res = await isExposure(params);
    if (res.data?.code == 200) {
      resolve(res.data.data);
      return;
    }
    Toast.fail(res.data?.message || res.status);
  });
}

export default adPopup;
