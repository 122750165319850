/** 自定义指令，执行点击事件 
 * 接收一个函数
 * 检查有没有token 
 * 1 无则执行微信授权流程
 * 2 有则继续执行绑定函数
 */
import authed from './authed'

const install = function(Vue) {
  Vue.directive('authed', authed)
}

if (window.Vue) {
  window['authed'] = authed
  Vue.use(install); // eslint-disable-line
}

authed.install = install
export default authed
