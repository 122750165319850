
let clocks = [
  {
    path: '/clocks/index',
    name: 'clockIndex',
    component: () => import('@/views/clocks/index.vue'),
    meta: {
      title: '打卡主页',
      guest: {
        click: true, //游客模式点击操作需要授权
      }
    }
  },
  {
    path: '/clocks/clocks',
    name: 'clocks',
    component: () => import('@/views/clocks/clocks.vue'),
    meta: {
      title: '打卡列表',
    }
  },
  {
    path: '/clocks/shareClocks',
    name: 'shareClocks',
    component: () => import('@/views/clocks/shareClocks.vue'),
    meta: {
      title: '分享打卡',
    },
  },
  {
    path: '/clocks/shareClock',
    name: 'shareClock',
    component: () => import('@/views/clocks/shareClock.vue'),
    meta: {
      title: '分享打卡',
    },
  },
  {
    path: '/clocks/clock',
    name: 'clock',
    component: () => import('@/views/clocks/clock.vue'),
    meta: {
      title: '打卡详情',
    },
  },
  {
    path: '/clocks/theme',
    name: 'theme',
    component: () => import('@/views/clocks/theme.vue'),
    meta: {
      title: '主题打卡',
    },
  },
  {
    path: '/clocks/clocking',
    name: 'clocking',
    component: () => import('@/views/clocks/clocking.vue'),
    meta: {
      title: '发布打卡',
      noback: true
    },
  },
  {
    path: '/clocks/myClock',
    name: 'myClock',
    component: () => import('@/views/clocks/myClock.vue'),
    meta: {
      title: '我的打卡'
    }
  },
  {
    path: '/clocks/calendar',
    name: 'calendar',
    component: () => import('@/views/clocks/calendar.vue'),
    meta: {
      title: '日历'
    }
  },
  {
    path: '/clocks/honour',
    name: 'honour',
    component: () => import('@/views/clocks/honour.vue'),
    meta: {
      title: '打卡证书'
    }
  },
]
export default clocks