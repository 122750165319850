// 弹框广告api
import request from "@/assets/js/http.js";

// 根据曝光页路由获取此页面最新的一个弹窗
export function getExposureByPage(params) {
  return request({
    url: "/activitys/ad/traffic/getExposureByPage",
    method: "get",
    params,
  });
}

// 查询广告弹窗规则 验证用户是否能弹窗
export function isExposure(params) {
  return request({
    url: "/activitys/ad/traffic/isExposure",
    method: "get",
    params,
  });
}

// 上报弹窗广告下单事件(埋点)
export function eventPointOrder(data) {
  return request({
    url: "/activitys/ad/traffic/eventPoint/order",
    method: "post",
    data,
  });
}

// 上报弹窗广告流量事件(埋点)
export function eventPoint(data) {
  return request({
    url: "/activitys/ad/traffic/eventPoint",
    method: "post",
    data,
  });
}
