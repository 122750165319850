let system = [
  {
    path: '/system/authorityList',
    name: 'authorityList',
    component: () => import('@/views/system/authority/list.vue'),
    meta: {
      title: '红杏林应用权限申请与使用说明',
      filterTkPage:true,
      noback:true
    }
  },
]

export default system
