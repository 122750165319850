const login = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
      filterTkPage: true,
      noback: true
    }
  },
]

export default login
