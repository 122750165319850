let medicals = [
	{
	  path: '/medicals/index',
	  name: 'medicineIndex',
		component: () => import('@/views/medicals/index.vue'),
	  meta: {
	    title: '医案主页',
	  }
	},
	{
	  path: '/medicals/medicals',
	  name: 'medicals',
		component: () => import('@/views/medicals/medicals.vue'),
	  meta: {
	    title: '我的医案',
	  }
	},
	{
	  path: '/medicals/patient_run',
	  name: 'patient_run',
		component: () => import('@/views/medicals/patient_run.vue'),
	  meta: {
	    title: '患者管理',
	  }
	},
	{
	  path: '/medicals/healthyCharts',
	  name: 'healthyCharts',
		component: () => import('@/views/medicals/healthyCharts.vue'),
	  meta: {
		title: '健康报告',
		freeAccess:true,
	  }
	},
	{
	  path: '/medicals/patient_add',
	  name: 'patient_add',
		component: () => import('@/views/medicals/patient_add.vue'),
	  meta: {
	    title: '患者信息',
	  }
	},
	{
	  path: '/medicals/patient',
	  name: 'patient',
		component: () => import('@/views/medicals/patient.vue'),
	  meta: {
	    title: '患者档案',
	  }
	},
	{
	  path: '/medicals/medicine_add',
	  name: 'medicine_add',
		component: () => import('@/views/medicals/medicine_add.vue'),
	  meta: {
	    title: '新增医案',
			noback:true
	  }
	},
	{
	  path: '/medicals/medicine_detail',
	  name: 'medicine_detail',
		component: () => import('@/views/medicals/medicine_detail.vue'),
	  meta: {
	    title: '医案详情',
	  }
	},
	{
	  path: '/medicals/returnVisit',
	  name: 'returnVisit',
		component: () => import('@/views/medicals/returnVisit.vue'),
	  meta: {
	    title: '复诊',
	  }
	},
	{
	  path: '/medicals/poster',
	  name: 'poster',
		component: () => import('@/views/medicals/poster.vue'),
	  meta: {
	    title: '分享医案',
	  }
	},
	{
	  path: '/medicals/medalList',
	  name: 'medalList',
		component: () => import('@/views/medicals/medalList.vue'),
	  meta: {
	    title: '我的勋章',
			keepAlive:true
	  }
	},
	{
	  path: '/medicals/disease',
	  name: 'disease',
		component: () => import('@/views/medicals/disease.vue'),
	  meta: {
	    title: '选择疾病名称',
	  }
	},
]

export default medicals