const serverSrc = process.env.VUE_APP_BASE_API;
const userInfo = { id: '' };
const userId = '';
// const token = "";
const token =
  process.env.VUE_APP_BASE_API == 'http://114.215.182.175:8208/'
    ? '3EC29D7578A35533EB6654705C365FD0'
    : '';
const sessionKey = 'userInfo';
export default {
  serverSrc, //服务器地址
  userInfo, //用户信息
  imgUrl: 'https://image.hongxinglin.com/hxl_static/hxlftp/',
  videoUrl: 'https://wxvideo.hongxinglin.com/hxl_static/', //视频播放前缀
  userId, //用户ID
  token, //token
  sessionKey,
  uploadUrl: '/common-server/app/getSignature',
};
