<template>
  <div class="login_pop">
    <van-popup v-model="$store.state.showAuthPop"
               round>
      <h3 class="login_pop_title">为您提供更好的服务</h3>
      <p class="login_pop_content">
        我们将通过
        <span class="blue"
              @click="jumpPage('userAgree')">《用户使用协议》</span>
        <span class="blue">和</span>
        <span class="blue"
              @click="jumpPage('privacyAgree')">《用户隐私政策》</span>
        帮助您了解我们收集、使用、存储和共享个人信息的情况
      </p>
      <div class="login_pop_footer">
        <van-button @click="cancelBtn"
                    class="cancel_btn"
                    type="primary"
                    color="#F5F5F5">拒绝并退出</van-button>
        <van-button @click="sureBtn"
                    class="sure_btn"
                    type="primary"
                    color="#CE5A43">同意并继续</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    methods: {
      jumpPage(name) {
        this.$store.commit("HIDE_AUTH_POP");
        this.openPage(name);
      },
      sureBtn() {
        // 授权并回跳给定页面 用户主动授权微信登录snsapi_userinfo
        this.$store.commit("WX_AUTH", {
          href: this.$store.state.authLink,
          authScope: this.$store.state.snsapi_userinfo,
        });
      },
      cancelBtn() {
        this.$store.commit("HIDE_AUTH_POP");
      },
    },
  };
</script>

<style lang="less" scoped>
  .login_pop {
    .van-popup {
      padding: 30px 26px 40px;
      width: 92%;
    }
    .login_pop_title {
      font-size: 22px;
      font-weight: 700;
      text-align: center;
      color: #313131;
      line-height: 30px;
    }
    .login_pop_content {
      margin-top: 24px;
      font-size: 16px;
      color: #313131;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
    .blue {
      color: #1592e6;
    }
    .login_pop_footer {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
    }
    .cancel_btn {
      padding: 0;
      width: 140px;
      height: 50px;
      .van-button__text {
        color: #313131;
        font-size: 18px;
      }
    }
    .sure_btn {
      padding: 0;
      width: 140px;
      height: 50px;
      .van-button__text {
        color: #fff;
        font-size: 18px;
      }
    }
  }
</style>