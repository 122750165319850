
let massage = {
    state:{
        step:1,
        childrenId:'',
        massageTypeId:'',
        json:{},
        objUser:null,
    },
    mutations:{
        massage_SETSTEP(state,step){
            state.step = step
        },
        massage_CHILDRENID(state,childrenId){
            state.childrenId = childrenId
        },
        massage_MASSAGETYPEID(state,massageTypeId){
            state.massageTypeId = massageTypeId
        },
        massage_JSONFN(state,json){
            state.json = json
        },
        massage_OBIUSER(state,objUser){
            state.objUser = objUser
        },
    },
    actions:{

    },
}
export default massage