let inviteCode = [
  {
    path: '/getInviteCode',
    name: 'GetInviteCode',
    component: () => import('@/views/getInviteCode/index.vue'),
    meta: {
      title: '领取邀请码',
      filterTkPage: true,
      noback: true
    }
  },
  {
    path: '/getInviteCode/inviteCode',
    name: 'inviteCode',
    component: () => import('@/views/getInviteCode/inviteCode.vue'),
    meta: {
      title: '邀请码',
      guest: {
        click: true, //游客模式点击操作需要授权
      }
    }
  },
]

export default inviteCode
