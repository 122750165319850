let discovers = [
	{
		path: '/discovers',
		name: 'discovers',
		component: () => import('@/views/discovers/index.vue'),
		meta: {
			title: '工具',
			hiddenTitle:true,
			noback:true
		}
	},
	{
	  path: '/discovers/sanzhen',
	  name: 'sanzhen',
	  component: () => import('@/views/discovers/sanzhen.vue'),
	  meta: {
	    title: '疼痛三针',
	  }
	},
	{
	  path: '/discovers/ttbz',
	  name: 'ttbz',
	  component: () => import('@/views/discovers/ttbz.vue'),
	  meta: {
	    title: '疼痛专题',
	  }
	},
	{
	  path: '/discovers/ttbz_details',
	  name: 'ttbz_details',
	  component: () => import('@/views/discovers/ttbz_details.vue'),
	  meta: {
	    title: '疼痛详情',
	  }
	},
	{
	  path: '/discovers/wxm',
	  name: 'wxm',
	  component: () => import('@/views/discovers/wxm.vue'),
	  meta: {
	    title: '五行脉',
	  }
	},
	{
	  path: '/discovers/yym',
	  name: 'yym',
	  component: () => import('@/views/discovers/yym.vue'),
	  meta: {
	    title: '阴阳脉',
	  }
	},
	{
	  path: '/discovers/mailuo_details',
	  name: 'mailuo_details',
	  component: () => import('@/views/discovers/mailuo_details.vue'),
	  meta: {
	    title: '脉络详情',
	  }
	},
	{
	  path: '/discovers/aj_details',
	  name: 'aj_details',
	  component: () => import('@/views/discovers/aj_details.vue'),
	  meta: {
	    title: '艾灸详情',
	  }
	},
	{
	  path: '/discovers/acupoint_query',
	  name: 'acupoint_query',
	  component: () => import('@/views/discovers/acupoint_query.vue'),
	  meta: {
	    title: '经络腧穴',
			keepAlive:true
	  }
	},
	{
	  path: '/discovers/acupoint_search',
	  name: 'acupoint_search',
	  component: () => import('@/views/discovers/acupoint_search.vue'),
	  meta: {
	    title: '搜索穴位',
			keepAlive:true
	  }
	},
	{
	  path: '/discovers/acupoint_details2',
	  name: 'acupoint_details2',
	  component: () => import('@/views/discovers/acupoint_details2.vue'),
	  meta: {
	    title: '经络详情',
	  }
	},
	{
	  path: '/discovers/acupoint_details',
	  name: 'acupoint_details',
	  component: () => import('@/views/discovers/acupoint_details.vue'),
	  meta: {
	    title: '穴位详情',
	  }
	},
	{
	  path: '/discovers/entry_details',
	  name: 'EntryDetails',
	  component: () => import('@/views/discovers/entry_details.vue'),
	  meta: {
	    title: '词条详情',
	  }
	},
	{
	  path: '/discovers/skill',
	  name: 'skill',
	  component: () => import('@/views/discovers/skill.vue'),
	  meta: {
	    title: '复位手法',
	  }
	},
	{
	  path: '/discovers/quxue',
	  name: 'quxue',
	  component: () => import('@/views/discovers/quxue.vue'),
	  meta: {
	    title: '病症取穴',
	  }
	},
	{
	  path: '/discovers/viscera',
	  name: 'viscera',
	  component: () => import('@/views/discovers/viscera.vue'),
	  meta: {
	    title: '脏腑点穴',
	  }
	},
	{
	  path: '/discovers/jishiMassage',
	  name: 'jishiMassage',
	  component: () => import('@/views/discovers/jishiMassage.vue'),
	  meta: {
		title: '获取推拿定制方',
		keepAlive:true
	  }
	},
	{
	  path: '/discovers/massageDetail',
	  name: 'massageDetail',
	  component: () => import('@/views/discovers/massageDetail.vue'),
	  meta: {
		title: '推拿详情',
		// keepAlive:true
	  }
	},
	// {
	//   path: '/discovers/MassageMore',
	//   name: 'massageMore',
	//   component: () => import('@/views/discovers/MassageMore.vue'),
	//   meta: {
	//     title: '查看表征',
	//   }
	// },
	{
	  path: '/discovers/massageLookMore',
	  name: 'massageLookMore',
	  component: () => import('@/views/discovers/massageLookMore.vue'),
	  meta: {
	    title: '查看表征',
	  }
	},
	{
		path: '/discovers/muscel',
		name: 'muscel',
		component: () => import('@/views/discovers/muscel.vue'),
		meta: {
		  title: '肌肉解剖',
			  keepAlive:false
		}
	},
	{
		path: '/discovers/muscelSearch',
		name: 'muscelSearch',
		component: () => import('@/views/discovers/muscelSearch.vue'),
		meta: {
		  title: '搜索肌肉名',
			  keepAlive:true
		}
	},
	{
		path: '/discovers/muscelDetails',
		name: 'muscelDetails',
		component: () => import('@/views/discovers/muscelDetails.vue'),
		meta: {
		  title: '太极刮痧肌肉解剖详情',
			//   keepAlive:true
		}
	},
]

export default discovers