import request from "@/assets/js/http.js";

export function sendCode(data) {
  return request({
    url: "/user/userSms/sendCode",
    method: "post",
    data: data,
  });
}

export function login(data) {
  return request({
    url: "/user/user/login",
    method: "post",
    data: data,
  });
}

export function bindphone(data) {
  return request({
    url: "/user/user/bindphone",
    method: "post",
    data: data,
  });
}

export function loginBindPhone(data) {
  return request({
    url: "/user/user/auth/loginBindPhone",
    method: "post",
    data: data,
  });
}

export function reBindingPhone(data) {
  return request({
    url: "/user/userSms/reBindingPhone",
    method: "post",
    data: data,
  });
}

export function getPhoneCode() {
  return request({
    url: "/user/country/getCode",
    method: "get",
  });
}

export function getUserCollection(data) {
  return request({
    url: "/user/userInfo/getMyFavorite",
    method: "get",
    params: data,
  });
}

export function userFeedBack(data) {
  return request({
    url: "/user/userFeed/saveFeedBack",
    method: "post",
    data: data,
  });
}

export function getNewsList(data) {
  return request({
    url: "/user/userInfo/getNoticeList",
    method: "get",
    params: data,
  });
}

export function getCertificateInfoDetails(data) {
  return request({
    url: "/user/persion/getCertificateInfoDetails",
    method: "get",
    params: data,
  });
}

export function getCertificate(data) {
  return request({
    url: "/user/persion/getCertificate?t=" + new Date().getTime(),
    method: "get",
    params: data,
  });
}

export function getMyToolNumber(data) {
  return request({
    url: "/user/userInfo/getMyToolNumber",
    method: "get",
  });
}

export function getMyCertificate(data) {
  return request({
    url: "/curriculum/certificate/getMyCertificate",
    method: "get",
    params: data,
  });
}

export function getCertificateDetails(data) {
  return request({
    url: "/curriculum/certificate/getCertificateDetails",
    method: "get",
    params: data,
  });
}

//   /logistics/getList  获取用户信息
export function getList(data) {
  return request({
    url: "/studytool/logistics/getList",
    method: "get",
    params: data,
  });
}

//   /logistics/getDeliveryInfo  查询运送详细
export function getDeliveryInfo(data) {
  return request({
    url: "/studytool/logistics/getDeliveryInfo",
    method: "get",
    params: data,
  });
}

/**检测用户信息*/
export function getUserById(data) {
  return request({
    url: "/user/userInfo/getUserByHuId",
    method: "get",
  });
}
// /**检测用户信息*/
// export function sendCode(data) {
//   return request({
//     url: '/user//user/sendCode',
//     method: 'post',
// 		data
//   })
// }

export function getUserFeedBackDic(data) {
  return request({
    url: "/user/userFeed/getUserFeedBackDic",
    method: "get",
  });
}
// 获取反馈详情
export function getFeedback(params) {
  return request({
    url: "/user/userFeed/getFeedback",
    method: "get",
    params,
  });
}

export function getSortLink(data) {
  return request({
    url: "/user/wxSortLink/getSortLink",
    method: "get",
    params: data,
  });
}

export function saveWechatVersion(data) {
  return request({
    url: "/user/userInfo/saveWechatVersion",
    method: "post",
    data,
  });
}
/**
 * h刷新token 此接口已废弃
 * @param {
 * token 缓存的token
 * userId 缓存的用户id
 * source: 0, // h5 的token 传0
 * } data
 * @returns
 */
// export function wxTokenInvalid(data) {
//   return request({
//     url: '/user/user/wxTokenInvalid',
//     method: 'get',
//     params: data,
//   })
// }
/**
 * app webview嵌入h5 用app的token 换 h5 的token
 * header Authorization app的token
 * source: 2, // app 的token 传2
 * @param {*} data
 * @returns
 */
export function tokenTransformGZH(data) {
  return request({
    url: "/user/user/auth/tokenTransformGZH",
    method: "get",
    params: data,
  });
}

// 是否已登录
export function getUserLogin(data) {
  return request({
    url: "/user/userInfo/getUserIsLogin",
    method: "get",
    params: data,
  });
}

// 获取多unid信息采集id
export function getCollectionId(data) {
  return request({
    url: "/user/user/craw/getCollectionId",
    method: "get",
    params: data,
  });
}

// 获取企微客服跳转链接
export function getEnterpriseWechatConf(data) {
  return request({
    url: "/adver-core/enterpriseWechatConf/get-use",
    method: "get",
    params: data,
  });
}

// 检测unionId是否存在多个用户
export function checkUser(data) {
  return request({
    url: "/user/user/auth/checkUser",
    method: "get",
    params: data,
  });
}

// unionId存在多个账户的用户选择账户
export function selectAccount(data) {
  return request({
    url: "/user/user/auth/selectAccount",
    method: "post",
    data,
  });
}

export function getTodaySubmitCount() {
  return request({
    url: "/user/userFeed/haveFeedbackToday",
    method: "get",
  });
}
