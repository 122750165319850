let courses = [{
  path: '/faceList',
  name: 'faceList',
  component: () => import('@/views/courses/faceCurriculum/list.vue'),
  meta: {
    title: '面授课列表',
  }
},
{
  path: '/faceDetails',
  name: 'faceDetails',
  component: () => import('@/views/courses/faceCurriculum/details.vue'),
  meta: {
    title: '面授课详情',
  }
},
{
  path: '/liveList',
  name: 'liveList',
  component: () => import('@/views/courses/liveCurriculum/list.vue'),
  meta: {
    title: '直播课堂',
  }
},
{
  path: '/liveDetails',
  name: 'liveDetails',
  component: () => import('@/views/courses/liveCurriculum/details.vue'),
  meta: {
    title: '直播详情',
  }
},
{
  path: '/systemList',
  name: 'systemList',
  component: () => import('@/views/courses/systemCurriculum/list.vue'),
  meta: {
    title: '系统课列表',
  }
},
{
  path: '/systemDetails',
  name: 'systemDetails',
  component: () => import('@/views/courses/systemCurriculum/details.vue'),
  meta: {
    title: '系统课详情',
  }
},
{
  path: '/singleDetails',
  name: 'singleDetails',
  component: () => import('@/views/courses/singleCurriculum/details.vue'),
  meta: {
    title: '单课详情',
    // noback: true
  }
},
{
  path: '/specialDetails',
  name: 'specialDetails',
  component: () => import('@/views/courses/specialCurriculum/details.vue'),
  meta: {
    title: '专栏课详情',
    navTop: true,
    // noback: true,
    guest: true
  }
},
{
  path: '/courseList', //单课、专栏列表
  name: 'courseList',
  component: () => import('@/views/courses/publicCurriculum/list.vue'),
  meta: {
    title: '课程列表',
    guest: true
  }
},


{
  path: '/courseTypes',
  name: 'courseTypes',
  component: () => import('@/views/courses/publicCurriculum/typeList.vue'),
  meta: {
    title: '课程分类',
  }
},

{
  path: '/evaluate',
  name: 'evaluate',
  component: () => import('@/views/courses/comment/evaluate.vue'),
  meta: {
    title: '课程评价',
  }
},
{
  path: '/myEvaluate',
  name: 'myEvaluate',
  component: () => import('@/views/courses/comment/myEvaluate.vue'),
  meta: {
    title: '我的评价',
  }
},
{
  path: '/commentList',
  name: 'commentList',
  component: () => import('@/views/courses/comment/commentList.vue'),
  meta: {
    title: '评价列表',
  }
},
{
  path: '/gather',
  name: 'gather',
  component: () => import('@/views/courses/comment/gather.vue'),
  meta: {
    title: '信息采集',
    noback: true
  }
},
{
  path: '/liveRoom/:id/:code/:status',
  name: 'liveRoom',
  component: () => import('@/views/courses/liveCurriculum/liveRoom.vue'),
  meta: {
    title: '直播间',
  }
},
{
  path: '/articleDetails',
  name: 'articleDetails',
  component: () => import('@/views/courses/specialCurriculum/articleDetails.vue'),
  meta: {
    title: '图文详情',
  }
},
{
  path: '/videoDetails',
  name: 'videoDetails',
  component: () => import('@/views/courses/specialCurriculum/videoDetails.vue'),
  meta: {
    title: '课程学习',
    noback: true
  }
},
{
  path: '/typeList',
  name: 'typeList',
  component: () => import('@/views/courses/publicCurriculum/typeList.vue'),
  meta: {
    title: '全部分类',
  }
},
{
  path: '/infoGather',
  name: 'infoGather',
  component: () => import('@/views/courses/infoGather/index.vue'),
  meta: {
    title: '问卷调查',
    noback: true,
    // filterTkPage: true,
    // guest: {
    //   showBar: true,
    //   bottom: 0
    // }
  }
},
{
  path: '/pay',
  name: 'pay',
  component: () => import('@/views/courses/pay/index.vue'),
  meta: {
    title: '结算台',
    noback: true
  }
},
{
  path: '/payTips',
  name: 'payTips',
  component: () => import('@/views/courses/pay/payTips.vue'),
  meta: {
    title: '课程支付',
  }
},
{
  path: '/singleIntroduce',
  name: 'singleIntroduce',
  component: () => import('@/views/courses/singleCurriculum/introduce.vue'),
  meta: {
    title: '单课简介',
  }
},
{
  path: '/specialIntroduce',
  name: 'specialIntroduce',
  component: () => import('@/views/courses/specialCurriculum/introduce.vue'),
  meta: {
    title: '专栏简介',
  }
},
{
  path: '/courses',
  name: 'courses',
  component: () => import('@/views/courses/courses.vue'),
  meta: {
    title: '课程列表',
  }
},
// {
//   path: '/shopList',
//   name: 'shopList',
//   component: () => import('@/views/courses/publicCurriculum/shopList'),
//   meta: {
//     title: '课程分类',
//   }
// },
{
  path: '/shopListNew',
  name: 'shopListNew',
  component: () => import('@/views/courses/publicCurriculum/shopListNew'),
  meta: {
    title: '课程分类',
  }
},
{
  path: '/bjyRoom',
  name: 'bjyRoom',
  component: () => import('@/views/courses/liveCurriculum/bjyRoom'),
  meta: {
    title: '红杏林直播',
    noback: true,
  }
},
{
  path: '/bjyPlayBack',
  name: 'bjyPlayBack',
  component: () => import('@/views/courses/liveCurriculum/bjyPlayBack'),
  meta: {
    title: '红杏林回看',
    noback: true,
  }
},
{
  path: '/saleCurriculumList',
  name: 'saleCurriculumList',
  component: () => import('@/views/courses/saleCurriculum/list'),
  meta: {
    title: '好课抢购',
    guest: true
  }
},
{
  path: '/classifyCurriculumList',
  name: 'classifyCurriculumList',
  component: () => import('@/views/courses/classifyCurriculum/list'),
  meta: {
    title: '全部课程',
    navTop: true,
    guest: true
  }
},
{
  path: "/userFeedback",
  name: "userFeedback",
  component: () => import("@/views/courses/specialCurriculum/userFeedback"),
  meta: {
    title: "用户反馈",
    navTop: false,
    guest: false,
  },
},
]
export default courses