<template>
  <div class="nav_top flex">
    <van-button class="nav_home"
                @click="openPage('home')">返回首页</van-button>
    <van-button class="nav_user"
                @click="openPage('user')">个人中心</van-button>
  </div>
</template>

<script>
  export default {
    mounted() {},
    activated() {
      console.log("activated");
    },
  };
</script>

<style lang="less" scoped>
  .nav_top {
    justify-content: space-between;
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
    .van-button {
      height: 36px;
      border: none;
    }
    .nav_home {
      font-size: 14px;
      // font-weight: 700;
      color: #313131;
    }
    .nav_user {
      font-size: 14px;
      font-weight: 400;
      color: #373737;
      .van-button__icon {
        font-size: 0;
      }
      .van-icon__image {
        width: 14.4px;
        height: initial;
      }
    }
  }
</style>