/**
 * 游客模式请求白名单
 * 以下接口游客模式不访问
 */
export default [
    "/user/userInfo/getUserByHuId",
    "/curriculum/credential/getCertificateRecordList",
    "/curriculum/curriculum/getUserNearCurrInfo",
    "/user/userInfo/saveWechatVersion",
    "/curriculum/curriculum/evaluateMessageList",
    "/curriculum/curriculum/checkUserIsSign",
    "/curriculum/studyCore/getMyStudyStatistics",
    "/curriculum/studyCore/cumulativeStudyNum",
    "/curriculum/studyCore/getStudyRecords",
    "/order/coupon/getIsGetCoupon",
    "/order/coupon/getMaxCouponAmount",
]