import request from '@/assets/js/http.js'
export function shareIng(data) {  //分享接口
  return request({
    url: 'common-server/common/shareIng',
    method: 'get',
    params:data
  })
}
 

// 获取图片生成() /common/getCreateImg
export function getCreateImg(data) { 
  return request({
    url: 'activitys/common/getCreateImg',
    method: 'get',
    params:data
  })
}

// 分页获取排行数据 /common/getRankings
export function getRankings(data) {
  return request({
    url: 'activitys/common/getRankings',
    method: 'get',
    params:data
  })
}

// 获取视频上传的签名
export function getSignature(data) {
  return request({
    url: 'common-server/app/getSignature',
    method: 'get',
    params:data
  })
}

export function getPublicImgUrl(data) {  //  获取图片公共 域名
    return request({
        url: 'common-server/common/getSystemConfigInfo',
        method: 'get',
        params: data
    })
}

export function queryDicByType(data) {//查询字典表
  return request({
    url: 'common-server/pc/dict-detail/map',
    params:data,
    method: 'get',
  })
}
export function sendPhoneCode(data) {// 获取验证码
  return request({
    url: 'common-server/verCodeController/no-auth/sendPhoneCode',
    params:data,
    method: 'get',
  })
}

export function dictDetailMap(data) {// 获取验证码
  return request({
    url: 'admin-auth/api/dictDetail/map',
    params:data,
    method: 'get',
  })
}

export function getTimer(data) {// 获取服务器时间
  return request({
    url: 'common-server/systemTimer/getTimer',
    params:data,
    method: 'get',
  })
}

export function getAuthorityInfo(data) {// 获取权限说明列表
  return request({
    url: 'common-server/authority/getAuthorityInfo',
    params:data,
    method: 'get',
  })
}

//获取行政区划数据
export function getRegion(data) {
	return request({
	  url: '/common-server/region/allAreas', // https://hxlimage.hongxinglin.com/region.json
	  method: 'get',
	})
}

/**
 * 通过短码获取原路径
 * @param {
 * dm
 * } data 
 * @returns 
 */
export function getLinkwidthDm(data) {
	return request({
	  url: '/go/sort/getLink',
	  method: 'get',
    params: data,
	})
}