import Vue from 'vue';
import { Toast } from 'vant';
import $Global from './Global.js';
Vue.use(Toast);
import { getData, postData } from './http';
import axios from 'axios';

let baseUrl = $Global.serverSrc;

function getSignature() {
  return axios
    .get(baseUrl + $Global.uploadUrl + '?isNeedParse=1')
    .then(function(res) {
      return res.data.data;
    });
}

function getShare(
  title,
  desc,
  shareLink = window.location.href,
  shareImg = 'https://image.hongxinglin.com/hxl_static/hxlftp/pubImage/9520200316123032.jpg'
) {
  let that = this;
  let params = new URLSearchParams();
  params.append('url', shareLink);
  params.append('version', '5.0');
  postData(baseUrl + '/common-server/common/shareIng', params)
    .then(function(res) {
      if (res.code == 200) {
        wx.config({
          debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.app_id, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'updateAppMessageShareData',
            'wx-open-subscribe',
          ], // 必填，需要使用的JS接口列表
        });

        wx.ready(function() {
          wx.checkJsApi({
            jsApiList: ['chooseImage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function(res) {
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            },
          });

          wx.onMenuShareAppMessage({
            title: title, // 分享标题,
            desc: desc, // 分享描述
            link: shareLink, // 分享链接
            imgUrl: shareImg, // 分享图标
            type: 'link', // 分享类型,music、video或link，不填默认为link
            // dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function() {
              // 用户确认分享后执行的回调函数
              setTimeout(function() {
                //回调要执行的代码
              }, 500);
            },
            cancel: function() {
              // 用户取消分享后执行的回调函数
              //alert(2)
            },
          });

          /**
           * 分享朋友圈
           */
          wx.onMenuShareTimeline({
            title: title, // 分享标题,
            desc: desc, // 分享描述
            link: shareLink, // 分享链接
            imgUrl: shareImg, // 分享图标
            type: 'link', // 分享类型,music、video或link，不填默认为link
            success: function() {
              setTimeout(function() {
                //回调要执行的代码
              }, 500);
              // 用户确认分享后执行的回调函数
            },
            cancel: function() {
              // 用户取消分享后执行的回调函数
            },
          });

          wx.error(function(res) {
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        });
      } else {
      }
    })
    .catch(function(error) {});
}

function iosBlur() {
  //ios input失焦
  let ua = window.navigator.userAgent;
  //$alert('浏览器版本: ' + app + '\n' + '用户代理: ' + ua);
  if (ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    //ios系统
    let currentPosition, timer;
    let speed = 1;
    timer = setInterval(function() {
      currentPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      currentPosition -= speed;
      window.scrollTo(0, currentPosition); //页面向上滚动
      currentPosition += speed;
      window.scrollTo(0, currentPosition); //页面向下滚动
      clearInterval(timer);
    }, 100);
  }
}

function two(num) {
  return num < 10 ? '0' + num : num;
}

function upload(files, busiName) {
  let toast = this.$toast.loading({
    message: '正在上传',
    duration: 0,
  });
  let file = files;
  let param = new FormData(); //创建form对象
  param.append('file', file); //通过append向form对象添加数据
  param.append('busiName', busiName); //通过append向form对象添加数据

  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      tk: $GLOBAL.token,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(
        baseUrl + '/common-server/aliFile/upLoadFileNoSecret',
        param,
        config
      )
      .then((res) => {
        Toast.clear();
        if (res.data.code == '-502') {
          this.$toast.fail(res.data.message);
          return;
        }
        this.$toast.success('上传成功');
        resolve(res.data);
      })
      .catch((res) => {
        Toast.clear();
        this.$toast.fail('上传失败' + res.message);
        reject(res);
      });
  });
}

function onBridgeReady(data) {
  return new Promise((resolve, reject) => {
    console.log(data, '拉起支付前的参数');
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        appId: data.wxAppId, //公众号名称，由商户传入
        timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
        nonceStr: data.nonceStr, //随机串
        package: data.packageName,
        signType: data.signType, //微信签名方式：
        paySign: data.paySign, //微信签名
      },
      function(res) {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          resolve();
        } else {
          reject(res);
        }
      }
    );
  });
}

// 格式化时间差 精确到1分
function formatTimeDiff(
  time,
  result = '',
  index = 0,
  unitMap = [
    { unit: 60, text: '分钟' },
    { unit: 24, text: '小时' },
    { unit: 30, text: '天' },
  ]
) {
  if (!result) time = Math.ceil(time / 1000 / 60);
  const temp = time % unitMap[index].unit;
  if (temp > 0) result = temp + unitMap[index].text + result;
  time = Math.floor(time / unitMap[index].unit);
  if (time > 0 && index <= 2) {
    index++;
    return formatTimeDiff(time, result, index, unitMap);
  }
  return result;
}

// 获取设备信息
function getDeviceInfo() {
  let webLog = {};
  let userAgent = navigator.userAgent;
  var temp;
  // 获取微信版本
  webLog.wechat = navigator.userAgent.replace(
    /^.*?MicroMessenger\/([\d\.]+).*$/g,
    '$1'
  );
  // 苹果手机
  if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    // 获取设备名
    if (userAgent.includes('iPad')) {
      webLog.device = 'iPad';
    } else {
      webLog.device = 'iPhone';
    }
    // 获取操作系统版本
    temp = userAgent.match(/iPhone OS .*?(?= )/);
    if (temp && temp.length > 0) {
      webLog.system = temp[0];
    }
  }
  // 安卓手机
  if (userAgent.includes('Android')) {
    // 获取设备名
    temp = userAgent.match(/Android.*; ?(.*(?= Build))/);
    if (temp && temp.length > 1) {
      webLog.device = temp[1];
    }
    // 获取操作系统版本
    temp = userAgent.match(/Android.*?(?=;)/);
    if (temp && temp.length > 0) {
      webLog.system = temp[0];
    }
  }
  return webLog;
}

export {
  getShare,
  iosBlur,
  two,
  upload,
  onBridgeReady,
  getSignature,
  formatTimeDiff,
  getDeviceInfo,
};
