
  let business = [
    {
      path: '/shoppingCart',
      name: 'shoppingCart',
			component: () => import('@/views/business/shoppingCart.vue'),
      meta: {
        title: '购物车',
      }
    },
    {
      path: '/addrList',
      name: 'addrList',
			component: () => import('@/views/business/addrList.vue'),
      meta: {
        title: '地址管理',
      }
    },
    {
      path: '/addr',
      name: 'addr',
			component: () => import('@/views/business/addr.vue'),
      meta: {
        title:'地址编辑',
      }
    },
    {
      path: '/shop',
      name: 'shop',
      component: () => import('@/views/business/shop.vue'),
      meta: {
        title: '店铺主页',
      }
    },
    {
      path: '/teacherList',
      name: 'teacherList',
			component: () => import('@/views/business/teacherList.vue'),
      meta: {
        title: '老师列表',
      }
    },
    {
      path: '/teacher',
      name: 'teacher',
			component: () => import('@/views/business/teacher.vue'),
      meta: {
        title: '老师主页',
      }
    },
    {
      path: '/orderList',
      name: 'orderList',
			component: () => import('@/views/business/orderList.vue'),
      meta: {
        title: '订单列表',
      }
    },
    {
      path: '/order',
      name: 'order',
			component: () => import('@/views/business/order.vue'),
      meta: {
        title: '订单详情',
      }
    },
    {
      path: '/logistics',
      name: 'logistics',
			component: () => import('@/views/business/logistics.vue'),
      meta: {
        title: '物流详情',
      }
    },
    {
      path: '/payFail',
      name: 'payFail',
			component: () => import('@/views/business/payFail.vue'),
      meta: {
        title: '支付失败',
      }
    },
    {
      path: '/alsoService',
      name: 'alsoService',
			component: () => import('@/views/business/alsoService.vue'),
      meta: {
        title: '退款/售后',
      }
    },
	]
 export default business