import Vue from 'vue';
import App from './App.vue';
import router from './routers/router';
import store from './store';
import global_ from './assets/js/Global';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css';
import {
  Button,
  Rate,
  Cell,
  CellGroup,
  Icon,
  Image as VanImage,
  Skeleton,
  Col,
  Row,
  Popup,
  Checkbox,
  CheckboxGroup,
  Toast,
  Dialog,
  DatetimePicker,
  Field,
  Picker,
  RadioGroup,
  Radio,
  Search,
  Switch,
  Uploader,
  ActionSheet,
  DropdownMenu,
  DropdownItem,
  Loading,
  Notify,
  Overlay,
  CountDown,
  Divider,
  Empty,
  List,
  Progress,
  Step,
  Steps,
  Sticky,
  Swipe,
  SwipeItem,
  Tag,
  Grid,
  GridItem,
  IndexBar,
  IndexAnchor,
  NavBar,
  Sidebar,
  SidebarItem,
  Tab,
  Tabs,
  Area,
  Form,
  Cascader,
} from 'vant';
import VueClipboard from 'vue-clipboard2'; //复制
import Router from 'vue-router';
import VueLazyload from 'vue-lazyload'; //图片懒加载
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';
import authed from '@/directive/authed';
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';
import './assets/quill.less';
Vue.use(authed);
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';

Vue.use(Button)
  .use(Tab)
  .use(Rate)
  .use(Tabs)
  .use(Cell)
  .use(CellGroup)
  .use(Icon)
  .use(VanImage)
  .use(Skeleton)
  .use(Col)
  .use(Row)
  .use(Popup)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Toast)
  .use(Dialog)
  .use(DatetimePicker)
  .use(Field)
  .use(Picker)
  .use(RadioGroup)
  .use(Radio)
  .use(Search)
  .use(Switch)
  .use(Uploader)
  .use(ActionSheet)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Loading)
  .use(Notify)
  .use(Overlay)
  .use(CountDown)
  .use(Divider)
  .use(Empty)
  .use(List)
  .use(Progress)
  .use(Step)
  .use(Sticky)
  .use(Steps)
  .use(Swipe)
  .use(SwipeItem)
  .use(Tag)
  .use(Grid)
  .use(GridItem)
  .use(IndexBar)
  .use(IndexAnchor)
  .use(NavBar)
  .use(Sidebar)
  .use(SidebarItem)
  .use(Tab)
  .use(Tabs)
  .use(Area)
  .use(VueLazyload)
  .use(preview)
  .use(Form)
  .use(Cascader);
Vue.config.ignoredElements = [
  'wx-open-launch-weapp',
  'wx-open-launch-app',
  'wx-open-subscribe',
]; //易宝支付跳转小程序
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.prototype.$toast = Toast;
Vue.prototype.$moment = moment;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$preview = function(urls, index = 0, absolutePath) {
  let imgs = [];
  if (absolutePath) {
    imgs = [...urls];
  } else {
    urls.forEach((e, i, a) => {
      imgs.push(store.state.imgUrl + e);
    });
  }
  wx.previewImage({
    current: imgs[index], // 当前显示图片的http链接
    urls: imgs, // 需要预览的图片http链接列表
  });
};
import { postData, getData } from './assets/js/http';
Vue.prototype.$post = postData;
Vue.prototype.$get = getData;
Vue.prototype.$driver = Driver;
import {
  getShare,
  iosBlur,
  two,
  upload,
  getSignature,
} from './assets/js/public';
// Vue.prototype.$ELEMENT = { size: 'small' };
Vue.prototype.$getShare = getShare;
Vue.prototype.$iosBlur = iosBlur;
Vue.prototype.$two = two;
// Vue.prototype.$play = play;
Vue.prototype.$upload = upload;
Vue.prototype.GLOBAL = global_;
Vue.prototype.$getSignature = getSignature;
Vue.mixin({
  mounted() {
    // console.log(process.env, '版本号');
    this.iosInputBack();
    //关闭图片预览
    if (this.$preview.self) {
      this.$preview.self.close();
    }
  },
  methods: {
    iosInputBack() {
      let ua = navigator.userAgent;
      if (/MicroMessenger/i.test(ua) && /(iPhone|iPad|iPod|iOS)/i.test(ua)) {
        let inputArr = document.querySelectorAll('input');
        inputArr.forEach((item) => {
          if (!item.addMixinBlur) {
            item.addMixinBlur = true;
            item.addEventListener('blur', () => {
              document.body.scrollTop = document.body.scrollTop;
            });
          }
        });
      }
    },
  },
});

Vue.prototype.openPage = function(name, par = {}) {
  this.$router.push({ name: name, query: par });
};
window.$GLOBAL = global_;
Vue.prototype.$GLOBAL = global_;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
