
<template>
  <div
    class="home_box"
    :class="{
      moveLeft: left,
      moveRight: right
    }"
    @click="goHome()"
    @touchstart.stop="mousedownFun"
    @touchend.stop="mouseupFun"
    :style="{ 'top': pageY + 'px', 'left': pageX + 'px' }"
  >
    <!-- 回首页 -->
    <img :src="$store.state.publicImgUrl+'static/hxlpt/discovers/muscle/icon_home.png'"
    />
    <br/>
    <span>首页</span>
  </div>
</template>
<script>
export default {
  name: "goHome",
  provide() {
    //直播间重载操作
    return {};
  },
  data() {
    return {
      isRouterAlive: true,
      pageX: 305,
      pageY: 464,
      left: false,
      right: false,

    };
  },
  mounted() {
    this.pageX = window.innerWidth - 50;
    this.pageY = window.innerHeight - 140
    // document.getElementById('app').addEventListener('touchend', this.mouseupFun);
  },
  methods: {
    goHome() {
      this.$emit("goHome");
    },
    mousedownFun(e) {
      window.addEventListener('touchmove', this.mousemoveFun, { passive: false });
      // this.pageX = e.changedTouches[0].pageX - 35;
      // this.pageY = e.changedTouches[0].pageY - 35;
      this.pageX = (this.pageX > window.innerWidth - 70 ? window.innerWidth - 70 : this.pageX);
      this.pageY = (this.pageY > document.getElementById('app').offsetHeight - 70 ? document.getElementById('app').offsetHeight - 70 : this.pageY);
      this.pageX = (this.pageX < 0 ? 0 : this.pageX);
      this.pageY = (this.pageY < 0 ? 0 : this.pageY);
    },
    mousemoveFun(e) {
      e.preventDefault();
      this.pageX = e.changedTouches[0].clientX - 35;
      this.pageY = e.changedTouches[0].clientY - 35;
      this.pageX = (this.pageX > window.innerWidth - 70 ? window.innerWidth - 70 : this.pageX);
      this.pageY = (this.pageY > document.getElementById('app').offsetHeight - 70 ? document.getElementById('app').offsetHeight - 70 : this.pageY);
      this.pageX = (this.pageX < 0 ? 0 : this.pageX);
      this.pageY = (this.pageY < 0 ? 0 : this.pageY);
      this.left = false;
      this.right = false;
    },
    mouseupFun(e) {
      if (this.pageX > (window.innerWidth / 2)) {
        this.right = true;
        this.left = false;
        this.setKeyframes('rightRun');
      } else {
        this.right = false;
        this.left = true;
        this.setKeyframes('leftRun');
      }
      window.removeEventListener('touchmove', this.mousemoveFun);
    },
    setKeyframes(animationName) {
      let style = document.styleSheets[0];
      console.log(this.pageX + '========pageX')
      style.insertRule(`
        @keyframes ${animationName} {
          from{
            left: ${this.pageX};
          }
          to {
            left: ${this.pageX > (window.innerWidth / 2) + 5 ? (window.innerWidth - 52) + 'px' : '10px'};
          }
        }
      `, 0)
      // this.pageX = this.pageX > (window.innerWidth / 2) + 5 ? (window.innerWidth - 70) : 0
    }
  },
};
</script>
<style lang="less" scoped>
.home_box {
  position: fixed;
  z-index: 999;
  width: 42px;
  height: 42px;
  line-height: initial;
  font-size: 0;
  // padding: 0 5px 0 10px;
  // border-radius: 13px 0 0 13px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.23);
  -webkit-user-select: none;
  user-select: none; 
  img {
    margin-top: 5px;
    width: 20.58px;
    height: 18.68px;
  }
  span {
    margin-top: 2.5px;
    display: inline-block;
    font-size: 8px;
    color: #2C323A;
    opacity: 0.8;
  }
}
.moveLeft {
  animation-name: leftRun;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.moveRight {
  animation-name: rightRun;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
</style>
