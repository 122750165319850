// meta.guest 为游客模式可访问页面
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import home from "./home.js";
import userRouter from "./user";
import discovers from "./discovers";
import medicals from "./medicals";
import activitys from "./activitys";
import clocks from "./clocks";
import courses from "./courses";
import business from "./business";
import store from "../store.js";
import exam from "./exam.js";
import inviteCode from "./inviteCode";
import system from "./system";
import login from "./login";

let routes = [
  ...home,
  ...userRouter,
  ...discovers,
  ...medicals,
  ...activitys,
  ...clocks,
  ...courses,
  ...business,
  ...exam,
  ...inviteCode,
  ...system,
  ...login,
];

const vueRouter = new Router({
  mode: "history",
  routes: routes,
  //页面重定向后不置顶处理
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

// var filterTkUrl = window.location.pathname
// 控制不验证tk的页面
var filterTkPage = false;

// var filterTkUrlList = [
//   '/medicals/healthyCharts'
// ]
vueRouter.beforeEach((to, from, next) => {
  console.log(to, from);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.query.afterSalesShow == true) {
    document.title = "退款/售后";
  }
  fixUpdateTitle();

  if (to.query.userOrigin == "app" && to.query.userToken) {
    // 来源是app的 获取app的token
    store.commit("SET_TOKEN", to.query.userToken);
    store.commit("SET_HUID", to.query.huid);
    // 把app(source 2) token 转化为 h5(target 0) 的token
    store.dispatch("ChangeAppToken", { source: 2, target: 0 }).then((res) => {
      next();
    });
    return;
  }

  // 微信授权地址
  var u = navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

  if (isiOS) {
    // 判断是否ios
    if (window.entryUrl == "" || window.entryUrl == undefined) {
      // var url = location.origin  + to.fullPath
      window.entryUrl = location.href;
    } else {
    }
    store.state.mobileSystem = 1;
  } else {
    // 安卓
    window.entryUrl = location.origin + to.fullPath;
    store.state.mobileSystem = 0;
  }

  // 控制不验证tk 的 属性
  if (to.meta.filterTkPage) {
    filterTkPage = true;
    next();
    return;
  }

  // var userObj = sessionStorage.getItem($GLOBAL.sessionKey);

  var tk =
    process.env.VUE_APP_BASE_API != "http://114.215.182.175:8208/"
      ? getQueryString("tk")
      : "197C148EF9249EE7BC0401BD6E6C1D65";
  var huid =
    process.env.VUE_APP_BASE_API != "http://114.215.182.175:8208/"
      ? getQueryString("huid")
      : "";
  // var tk = "50A4E23EF39FB0D2426D813F0A9DCD2A"; //万能token

  // if(filterTkPage){

  // }else
  const curHash = to.hash;
  if (curHash && curHash.indexOf("authScope=") > -1) {
    const authData = getAuthData(curHash);
    if (authData.tk) {
      const { tk, huid, loginUnionId: unionId, openId } = authData;
      $GLOBAL.token = tk;
      store.commit("SET_TOKEN", tk);
      store.commit("SET_HUID", huid);
      // 检测该用户是否多unionId用户，获取多unionId用户账号数据表
      store.dispatch("CheckUser", { huid }).then((unionIdUsers) => {
        if (unionIdUsers.length) {
          // 跳转至多unionId列表 供用户选择相应用户访问系统
          next({
            path: "/user/switchAccount",
            query: {
              // 切换用户后需要授权重新登录，link为回跳地址
              link: location.href.replace(/\#.*$/, ""),
            },
          });
        } else {
          next({
            path: to.path,
            query: to.query,
          });
        }
      });
      // store.dispatch('SaveVersion', 1)
      return;
    } else if (authData.authScope == store.state.snsapi_base) {
      // snsapi_base 授权回跳回来，如果无登录态信息，只可进入游客模式页面，其余页面需要跳转登录页面 走snsapi_userinfo授权登录
      store.commit("SET_AUTH_SCOPE", store.state.snsapi_base);
      if (!to.meta.guest) {
        next({
          path: "login",
          query: {
            // snsapi_userinfo主动授权后的回跳地址  去除携带的snsapi_base的授权信息
            link: location.href.replace(/\#.*$/, ""),
          },
        });
      } else {
        next({
          path: to.path,
          query: to.query,
        });
      }
    }
  }
  // if (tk != null && "" != tk && to.query.tk && !from.name) { // 微信授权返回
  //   $GLOBAL.token = tk;
  //   store.commit('SET_TOKEN', tk)
  //   store.commit('SET_HUID', huid)
  //   store.dispatch('SaveVersion', 1)
  //   // 移除地址栏授权参数
  //   const query = { ...to.query }
  //   delete query.tk
  //   delete query.huid
  //   delete query.userToken
  //   next({
  //     ...to,
  //     query
  //   })
  //   // replaceParamVal('tk', '')  // 此方法会使页面在跳转一次
  // }

  if (!store.state.userToken) {
    const tk = localStorage.getItem("userToken") || "";
    const huid = localStorage.getItem("huid") || "";
    if (tk) {
      store.commit("SET_TOKEN", tk);
      store.commit("SET_HUID", huid);
    }
  }

  // if (!from.name) { // 第一次进入页面 或 页面刷新
  if (store.state.imgUrl == "" || !store.state.imageUrlSync) {
    store.dispatch("SetPublicImgUrl");
  }
  // if (!store.state.userInfo || !store.state.userInfo.id) {
  //   store.dispatch('GetUser')
  // }
  // }

  if (store.state.dics == "") {
    store.dispatch("queryDicByType");
  }

  if (
    !store.state.userToken &&
    store.state.authScope !== store.state.snsapi_base
  ) {
    // 未登录统一去登录，snsapi_base授权
    store.commit("WX_AUTH", {
      href: location.origin + to.fullPath,
    });
    return;
  }

  if (store.state.userToken) {
    // 获取机型以及微信信息到本地用户判定支付方式，并把信息上传到服务端
    if (!store.state.version_wx) {
      store.dispatch("SaveVersion").then(() => {
        // 通过链接pay_way 调试指定支付方式
        if (to.query.pay_way == 1) {
          store.commit("SET_IS_LOW_WX", false);
        } else if (to.query.pay_way == 2) {
          store.commit("SET_IS_LOW_WX", true);
        }
      });
    }
  }

  next();
});

vueRouter.afterEach((to, from) => {
  // 路由切换 关闭 打开的广告弹框
  store.commit("SET_VISIBLE", false);
  // 弹框广告
  const popupPageMap = {
    "/learningCenter": "/learningCenter",
    "/medicals/index": "/medicals/medicine_detail",
    "/activeList": "/medicals/index",
    "/user/logisticsQuery": "/user/logisticsQuery",
    "/home": "/home",
    "/discovers": "/discovers",
  };

  // 访问就弹出的页面
  let pageRoute = popupPageMap[to.path];

  // 默认访问不弹出 做了一定操作后需要访问就弹出的页面  例：信息采集提交后
  console.log(store);
  let tempPage = store.getters.tempPage;
  if (!pageRoute && tempPage && tempPage.to.includes(to.path)) {
    pageRoute = tempPage.pageRoute;
    // 清除记录 恢复到访问不查询弹出广告
    store.commit("SET_TEMPPAGE");
  }
  if (pageRoute) {
    store.dispatch("GetExposure", { pageRoute });
  }
});

/**获取用户token */
function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

function replaceParamVal(paramName, replaceWith) {
  var oUrl = window.location.href.toString();
  var re = eval("/(" + paramName + "=)([^&]*)/gi");
  var nUrl = oUrl.replace(re, "");
  window.location = nUrl;
}

function getAuthData(hash) {
  const temp = hash.replace("#", "").split("&");
  const authData = {};
  for (let i = temp.length - 1; i >= 0; i--) {
    const item = temp[i];
    const [key, value] = item.split("=");
    if (key) authData[key] = value;
  }
  return authData;
}

function fixUpdateTitle() {
  // 兼容部分ios机型标题修改无效的问题
  if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    var i = document.createElement("iframe");
    i.src = "/favicon.ico";
    i.style.display = "none";
    i.onload = () => {
      setTimeout(() => {
        i.remove();
      }, 9);
    };
    document.body.appendChild(i);
  }
}

export default vueRouter;
